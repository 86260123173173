var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/access-manager.error.ts
var AccessManagerError = class extends Error {
  static {
    __name(this, "AccessManagerError");
  }
};

// src/grant-permission/grant-permission.ts
var PERMISSION_GRANTED = "permission-granted";
var TeamNotFound = class extends AccessManagerError {
  static {
    __name(this, "TeamNotFound");
  }
  constructor(code) {
    super(`L'\xE9quipe ${code} est introuvable`);
  }
};
var GrantPermission = class {
  constructor(teams, events) {
    this.teams = teams;
    this.events = events;
  }
  static {
    __name(this, "GrantPermission");
  }
  async apply({ to, permission }) {
    const team = await this.teams.find(to);
    if (!team) throw new TeamNotFound(to);
    if (team.permissions.includes(permission)) return team;
    const permissions = [...team.permissions, permission];
    const saved = await this.teams.save({ code: team.code, permissions });
    this.events.publish({ type: PERMISSION_GRANTED, data: { to, permission } });
    return saved;
  }
};

// src/revoke-permission/revoke-permission.ts
var PERMISSION_REVOKED = "permission-revoked";
var RevokePermission = class {
  constructor(teams, events) {
    this.teams = teams;
    this.events = events;
  }
  static {
    __name(this, "RevokePermission");
  }
  async apply(request) {
    const { permission, from: team } = request;
    const hasPermission = await this.teams.is(team).ableTo(permission);
    if (!hasPermission) return;
    await this.teams.as(team).revoke(permission);
    this.events.publish({ type: PERMISSION_REVOKED, data: request });
  }
};

// src/admin.constant.ts
var ADMIN = "admin";

// src/join-teams/join-teams.ts
var TEAMS_JOINED = "teams-joined";
var SomeTeamsNotFound = class extends AccessManagerError {
  static {
    __name(this, "SomeTeamsNotFound");
  }
  constructor(teams) {
    super(`Certaines des \xE9quipes parmis ${teams.join(", ")} sont introuvables`);
  }
};
var AdminAssignmentError = class extends AccessManagerError {
  static {
    __name(this, "AdminAssignmentError");
  }
  constructor() {
    super("Tu ne peux pas g\xE9rer l'\xE9quipe admin");
  }
};
var JoinTeams = class {
  constructor(memberships, events) {
    this.memberships = memberships;
    this.events = events;
  }
  static {
    __name(this, "JoinTeams");
  }
  async apply(request) {
    const { member, teams, teamManager } = request;
    this.checkAdminArrival(teams, teamManager);
    const allTeamsExist = await this.memberships.all(teams).exist();
    if (!allTeamsExist) throw new SomeTeamsNotFound(teams);
    const isMember = await this.memberships.is(member.id).memberOf(teams);
    if (isMember) return;
    await this.memberships.join(teams).as(member);
    this.events.publish({ type: TEAMS_JOINED, data: { member, teams } });
  }
  checkAdminArrival(teams, teamManager) {
    if (!teams.includes(ADMIN)) return;
    if (teamManager.canManageAdmins) return;
    throw new AdminAssignmentError();
  }
};

// src/leave-team/leave-team.ts
var AdminUnassignmentError = class extends AccessManagerError {
  static {
    __name(this, "AdminUnassignmentError");
  }
  constructor() {
    super("Tu ne peux pas g\xE9rer l'\xE9quipe admin");
  }
};
var TEAM_LEFT = "team-left";
var LeaveTeam = class {
  constructor(memberships, events) {
    this.memberships = memberships;
    this.events = events;
  }
  static {
    __name(this, "LeaveTeam");
  }
  async apply(request) {
    const { team, member, teamManager } = request;
    this.checkAdminLeave(team, teamManager);
    const isMember = await this.memberships.is(member.id).memberOf(team);
    if (!isMember) return;
    this.memberships.leave(team).as(member);
    this.events.publish({ type: TEAM_LEFT, data: { member, team } });
  }
  checkAdminLeave(team, teamManager) {
    if (team !== ADMIN) return;
    if (teamManager.canManageAdmins) return;
    throw new AdminUnassignmentError();
  }
};
export {
  AccessManagerError,
  GrantPermission,
  JoinTeams,
  LeaveTeam,
  PERMISSION_GRANTED,
  PERMISSION_REVOKED,
  RevokePermission,
  TEAMS_JOINED,
  TEAM_LEFT
};
